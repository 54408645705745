import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';

// Domain
import {
  SearchByCustomerDto,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type GoalLink = {
  is_active: boolean;
  status: string;
  name: string;
  icon: string;
  alt: string;
  active_amount: number;
  button_text: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class LinkGoalsViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.goals.link-goals';

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  is_loading = true;

  show_flagship_link_wealth_wizard = false;

  associated_contract = true;

  has_at_least_one_goal_contracted = false;

  show_distribute_balance_unallocated_modal = false;

  associated_product_id = '';

  unassigned_balance = 0;

  goals: Array<GoalLink> = [];

  reload = false;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  constructor() {
    this.addWealthLInkedGoalEventListener();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getCustomizedModerateClass = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary' : '');

  getCustomizedModerateFontColorClass = (is_moderate: boolean) => ((is_moderate) ? 'white--text' : 'primary--text');

  getCustomizedModerateButtonColorClass = (is_moderate: boolean) => ((is_moderate) ? 'white' : 'sky-blue');

  getCustomizedModerateButtonFontColorClass = (is_moderate: boolean) => ((is_moderate) ? 'primary--text' : 'white--text');

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '35px' : '25px');

  getIconCustomClass = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? 'mt-2 custom-uploaded-img' : 'mt-3');

  linkGoals = () => {
    this.show_flagship_link_wealth_wizard = true;
  }

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }

  distribute = () => {
    this.show_distribute_balance_unallocated_modal = true;
  }

  addWealthLInkedGoalEventListener = () => {
    window.addEventListener('wealth.linked.goal', this.reloadGoals);
  }

  verifyAction = (is_goal_active: boolean, index: number) => {
    if (is_goal_active) {
      if (this.goals[index].status === 'unassigned_amount') {
        this.distribute();
      } else {
        this.goToDashboardGoals();
      }
    } else {
      this.linkGoals();
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const investment_product = (investment_products.find(
        (item) => item.name === 'sowos_wealth',
      ));
      this.search_by_customer_dto.associated_product_id = investment_product!.id;
      this.associated_product_id = investment_product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_investment_products_query'));
    }
  }

  loadSearchByCustomerActiveGoal = async () => {
    try {
      this.search_by_customer_dto.is_active = true;
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      let is_moderate = false;
      active_goals.forEach((goal) => {
        is_moderate = false;
        if (goal.investment_product_fund_types && goal.investment_product_fund_types.linked) {
          is_moderate = verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          );
        }
        this.goals.push({
          is_active: goal.is_active,
          status: (goal.is_active) ? '' : this.translate('unlinked'),
          active_amount: goal.active_goal_amount || 0,
          name: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name
            : goal.investment_goal_type.label,
          icon: (goal.custom_investor_goal)
            ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
          alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
          button_text: (goal.is_active) ? this.translate('go_to_goals') : this.translate('link_goal'),
          is_moderate,
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_search_by_customer_active_goal'));
      }
    }
  }

  loadSearchByCustomerInactiveGoals = async () => {
    try {
      this.search_by_customer_dto.is_active = false;
      // eslint-disable-next-line max-len
      const inactive_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);

      inactive_goals.forEach((goal) => {
        this.goals.push({
          is_active: goal.is_active,
          status: (goal.is_active) ? '' : this.translate('unlinked'),
          active_amount: goal.active_goal_amount || 0,
          name: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name
            : goal.investment_goal_type.label,
          icon: (goal.custom_investor_goal)
            ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
          alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
          button_text: (goal.is_active) ? this.translate('go_to_goals') : this.translate('link_goal'),
          is_moderate: false,
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_search_by_customer_inactive_goal'));
      }
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      const custom_investor_goal_balance = await this.get_customer_investor_goal_balance_query
        .execute({ investment_product_id: this.associated_product_id });
      if (custom_investor_goal_balance.unassigned_balance) {
        this.unassigned_balance = custom_investor_goal_balance.unassigned_balance;
        this.goals.push({
          is_active: true,
          status: 'unassigned_amount',
          active_amount: this.unassigned_balance,
          name: this.translate('unassigned_amount'),
          icon: 'noun-coins.svg',
          alt: 'alts.unassigned',
          button_text: this.translate('distribute'),
          is_moderate: false,
          icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
        });
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_unassigned_balance'));
      }
    }
  }

  reloadGoals = async () => {
    this.reload = true;
    await this.initialize();
  }

  initialize = async () => {
    this.is_loading = true;
    this.goals.length = 0;
    await this.loadInvestmentProducts();
    await this.loadSearchByCustomerActiveGoal();
    await this.loadSearchByCustomerInactiveGoals();
    const has_at_least_one_goal_contracted = (this.goals.find(
      (item) => item.is_active,
    ));
    if (has_at_least_one_goal_contracted) {
      this.has_at_least_one_goal_contracted = true;
      await this.loadCustomerInvestorGoalBalance();
    }
    this.reload = false;
    this.is_loading = false;
  }
}
