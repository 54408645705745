















































































import { Component, Vue } from 'vue-property-decorator';
import LinkGoalsViewModel
  from '@/vue-app/view-models/allianz-dashboard/goals/link-goals-view-model';

@Component({
  name: 'LinkGoalsCard',
  components: {
    FlagshipLinkGoalsWizardWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardWealth.vue'),
    DistributeBalanceUnallocatedWealthModal: () => import('@/vue-app/components/allianz-dashboard/goals/DistributeBalanceUnallocatedWealthModal.vue'),
    LoadingDataCard: () => import('@/vue-app/components/home-dashboard/LoadingDataCard.vue'),
  },
})
export default class GoalsUndefinedCard extends Vue {
  link_goals_view_model = Vue.observable(new LinkGoalsViewModel());

  async created() {
    await this.link_goals_view_model.initialize();
  }
}
